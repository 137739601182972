<template>
  <main id="Basic">
    <CContainer>
      <CCard>
        <CCardHeader>
          <h5 class="mb-0">{{ $t('Navigation.Channel/B2B') }}</h5>
        </CCardHeader>
        <CCardBody>
          <CTabs variant="pills" :vertical="{ navs: 'col-lg-2 col-md-3 col-sm-12 col-12 mb-4', content: 'col-lg-10 col-md-9 col-sm-12 col-12' }">
            <CTab :title="$t('Channel/B2B.Info')">
              <h5 class="d-flex justify-content-between">
                  {{ $t('Channel/B2B.Info') }}
              </h5>
              <hr>
              <CRow>
                <CCol lg="6">
                  <CInput horizontal v-model="BasicData.B2BInfo.Title" :label="$t('Channel/B2B.Title')" />
                  <CInput horizontal v-model="BasicData.B2BInfo.SubTitle" :label="$t('Channel/B2B.SubTitle')"  />
                  <CInput horizontal v-model="BasicData.B2BInfo.OGDescription" :label="$t('System/Basic.OG:Description')"  />
                </CCol>
                <CCol lg="6">
                  <CInput horizontal v-model="BasicData.B2BInfo.Description" :label="$t('System/Basic.Description')" />
                  <CInput horizontal v-model="BasicData.B2BInfo.Keywords" :label="$t('System/Basic.Keywords')" />
                  <CInput
                    type="text"
                    :label="$t('System/Basic.OG:Image')"
                    v-model="BasicData.B2BInfo.OGImage"
                    horizontal
                  >
                    <template #append>
                      <CButton type="button" color="dark" @click="OpenMediaStore('B2BInfo', false, false, 'OGImage')">{{ $t('Global.Browse') }}</CButton>
                    </template>
                  </CInput>
                </CCol>
                  <CCol lg="6">
                      <CInput horizontal v-model="BasicData.B2BInfo.GoogleSearchConsoleCode" :label="$t('System/Basic.GoogleSearchConsoleCode')" :description="$t('System/Basic.GoogleSearchConsoleCodeInfo')" />
                  </CCol>
                  <CCol lg="6">
                      <CInput horizontal v-model="BasicData.B2BInfo.BingConsoleCode" :label="$t('System/Basic.BingConsoleCode')" :description="$t('System/Basic.BingConsoleCodeInfo')" />
                  </CCol>
                  <CCol lg="6">
                      <CRow class="mb-3">
                          <CCol tag="label" sm="3" class="col-form-label">
                              {{ $t('System/Basic.Logo') }}
                          </CCol>
                          <CCol xl="4" lg="6" class="px-1 mb-2">
                            <div v-if="BasicData.B2BInfo.Logo" class="position-relative CarouselItem">
                              <img v-lazy="BasicData.B2BInfo.Logo" class="img-fluid" />
                              <div class="CarouselTools">
                                <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('B2BInfo', false, false, 'Logo')">
                                  <CIcon name="cil-pencil" />
                                </CButton>
                              </div>
                            </div>
                            <div v-else class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                              <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                              <div class="CarouselTools">
                                <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('B2BInfo', false, false, 'Logo')">
                                  <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                                </CButton>
                              </div>
                            </div>
                          </CCol>
                      </CRow>
                  </CCol>
                  <CCol lg="6">
                      <CRow class="mb-3">
                          <CCol tag="label" sm="3" class="col-form-label">
                              {{ $t('System/Basic.Favicon') }}
                          </CCol>
                          <CCol xl="4" lg="6" class="px-1 mb-2">
                            <div class="favicon">
                              <div v-if="BasicData.B2BInfo.Favicon" class="position-relative CarouselItem">
                                <img v-lazy="BasicData.B2BInfo.Favicon" class="img-fluid" />
                                <div class="CarouselTools">
                                  <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('B2BInfo', false, false, 'Favicon')">
                                    <CIcon name="cil-pencil" />
                                  </CButton>
                                </div>
                              </div>
                              <div v-else class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                                <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                                <div class="CarouselTools">
                                  <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('B2BInfo', false, false, 'Favicon')">
                                    <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                                  </CButton>
                                </div>
                              </div>
                            </div>
                          </CCol>
                      </CRow>
                  </CCol>
              </CRow>
              <hr>
              <div class="d-flex justify-content-between">
                <CButton @click="SetData('B2BInfo')" color="success">{{ $t('Global.Confirm') }}</CButton>
              </div>
            </CTab>
            <CTab :title="$t('System/Themes.MainCarousel')">
              <h5 class="d-flex justify-content-between mb-3">
                {{ $t('System/Themes.MainCarousel') }}
              </h5>
              <hr>
              <Carousel key="CarouselDefault" :data.sync="BasicData.B2BInfo.Carousel" :title="$t('System/Themes.MainCarousel')" @save="SetData('B2BInfo')" />
            </CTab>
            <CTab :title="$t('Channel/B2B.Identity')">
              <h5 class="d-flex justify-content-between mb-3">
                {{ $t('Channel/B2B.Identity') }}
              </h5>
              <CAlert color="info">
                <CIcon name="cil-bell" /> 若商品未從B2B平台的商品價格設定內直接設定經銷價格，則將直接從商品管理內的「<b>實際售價</b>」為基礎運算折扣金額。
              </CAlert>
              <CDataTable
                id="AgentList"
                :items="BasicData.B2BIdentity"
                :fields="Field"
                :loading="Loading"
                :noItemsView="noItemsView"
                responsive
                hover
              >
                <template #Key="{index}">
                  <td class="text-center">
                    {{ index + 1 }}
                  </td>
                </template>
                <template #Name="{item, index}">
                  <td>
                    <CInput
                      v-if="CurrentEditIdentity === index"
                      v-model="item.Name"
                      type="text"
                      size="sm"
                    />
                    <span v-else>{{ item.Name }}</span>
                  </td>
                </template>
                <template #ShopDiscount="{item, index}">
                  <td>
                    <CInput
                      v-if="CurrentEditIdentity === index"
                      v-model="item.ShopDiscount"
                      type="number"
                      size="sm"
                    />
                    <span v-else>{{ item.ShopDiscount }}%</span>
                  </td>
                </template>
                <template #Status="{item, index}">
                  <td>
                    <CSwitch v-if="CurrentEditIdentity === index" color="success" :checked.sync="item.Status" />
                    <CBadge v-else :color="(item.Status === true ? 'success' : 'danger')">
                      {{ $t('Logistics.StatusType.' + item.Status) }}
                    </CBadge>
                  </td>
                </template>
                <template #Action="{item, index}">
                  <td>
                    <CButton v-if="CurrentEditIdentity !== index" color="info" size="sm" class="mr-1" @click="CurrentEditIdentity = index">
                      <CIcon name="cil-pencil" class="c-icon-custom-size"/>
                    </CButton>
                    <CButton v-if="CurrentEditIdentity === index" color="success" size="sm" class="mr-1" @click="CurrentEditIdentity = null">
                      <CIcon name="cil-check-circle" class="c-icon-custom-size"/>
                    </CButton>
                    <CButton v-if="item.ID !== 'A'" color="danger" size="sm" @click="BasicData.B2BIdentity.splice(index, 1)">{{ $t('Global.Delete') }}</CButton>
                  </td>
                </template>
              </CDataTable>
              <hr>
              <div class="d-flex justify-content-between">
                <CButton @click="AddData('B2BIdentity')" color="info">{{ $t('Global.Add') }}</CButton>
                <CButton @click="SetData('B2BIdentity')" color="success">{{ $t('Global.Confirm') }}</CButton>
              </div>
            </CTab>
            <CTab :title="$t('Channel/B2B.Product')">
              <h5 class="d-flex justify-content-between mb-3">
                {{ $t('Channel/B2B.Product') }}
              </h5>
              <CDataTable
                :loading="Loading"
                id="B2BProduct"
                :items="BasicData.B2BProduct"
                :fields="TargetItemField"
                :noItemsView="noItemsView"
                responsive
                hover
              >
                <template #Cover="{item}">
                  <td>
                    <img v-lazy="item.Cover" width="60" height="60" class="img-fluid"/>
                  </td>
                </template>
                <template #Name="{item}">
                  <td>
                    {{ item.Name }}<br>
                    <small><span class="font-weight-bold">{{ $t('Product/List.SeriesNum') }}</span>: {{ item.SeriesNum }}</small>
                  </td>
                </template>
                <template v-for="(FieldData) in BasicData.B2BIdentity" v-slot:[FieldData.ID]="{item, index}">
                  <td :key="FieldData.ID">
                    <CInput
                      v-if="CurrentEditProduct === index"
                      v-model="item.Amount[FieldData.ID]"
                      type="text"
                      size="sm"
                    />
                    <span v-else>{{ item.Amount[FieldData.ID] }}</span>
                  </td>
                </template>
                <template #Action="{index}">
                  <td>
                    <CButton v-if="CurrentEditProduct !== index" color="info" size="sm" class="mr-1" @click="CurrentEditProduct = index">
                      <CIcon name="cil-pencil" class="c-icon-custom-size"/>
                    </CButton>
                    <CButton v-if="CurrentEditProduct === index" color="success" size="sm" class="mr-1" @click="CurrentEditProduct = null">
                      <CIcon name="cil-check-circle" class="c-icon-custom-size"/>
                    </CButton>
                    <CButton color="danger" size="sm" v-c-tooltip="$t('Global.Remove')" @click="BasicData.B2BProduct.splice(index, 1)">
                      <CIcon name="cil-trash" class="c-icon-custom-size" />
                    </CButton>
                  </td>
                </template>
              </CDataTable>
              <hr>
              <div class="d-flex justify-content-between">
                <CButton @click="OpenProductListModel('Default')" color="info">{{ $t('Global.Add') }}</CButton>
                <CButton @click="SetData('B2BProduct')" color="success">{{ $t('Global.Confirm') }}</CButton>
              </div>
            </CTab>
          </CTabs>
          <CElementCover v-if="(Loading === true)" :opacity="0.75">
            <CSpinner color="success"/>
          </CElementCover>
        </CCardBody>
      </CCard>
    </CContainer>
    <MediaStore :Config="MediaStoreConfig" @OK="SetImages(ChooseImageType, ChooseImageIndex, ChooseImageItem)" />
    <component :is="ProductListModelComponent" :Single="Single" :Toggle="ProductListModel" :ChooseProductType="ChooseProductType" @ChooseProduct="ChooseProduct" />
  </main>
</template>

<route>
{
  "meta": {
    "label": "B2B平台"
  }
}
</route>

<script>
import crypto from 'crypto'

export default {
  name: 'BasicSetting',
  layout: 'manage',
  components: {
    Carousel: () => import('@/components/themes/Carousel'),
    MediaStore: () => import('@/components/public/Media')
  },
  data () {
    return {
      BasicData: {
        B2BInfo: {
          Carousel: []
        },
        B2BIdentity: [
          {
            ID: 'A',
            Name: '一般經銷商',
            ShopDiscount: 100,
            Status: true
          }
        ],
        B2BProduct: []
      },
      Loading: false,
      Submit: false,
      CurrentEditIdentity: null,
      CurrentEditProduct: null,
      ChooseImageType: '',
      ChooseImageIndex: false,
      ChooseImageItem: '',
      MediaStoreConfig: {
        Display: false,
        ChooseImageList: [],
        Multiple: false
      },
      Features: [],
      ProductListModel: false,
      ProductListModelComponent: null,
      ChooseProductType: null,
      ChooseConditionIndex: null,
      Single: false,
      noItemsView: {
        noResults: this.$t('Global.NoResults'),
        noItems: this.$t('Global.NoItems')
      }
    }
  },
  computed: {
    Field() {
      return [
        {key: 'Key', label: ''},
        {key: 'Name', label: '名稱'},
        {key: 'ShopDiscount', label: '折扣比例'},
        {key: 'Status', label: '狀態'},
        {key: 'Action', label: ''}
      ]
    },
    TargetItemField () {
      const Data = [
        { key: 'Cover', label: this.$t('Product/List.Cover') },
        { key: 'Name', label: this.$t('Product/List.Name') },
      ]
      this.BasicData.B2BIdentity.forEach((item) => {
        Data.push({ key: item.ID, label: item.Name })
      })
      Data.push({ key: 'Action', label: '' })
      return Data
    },
  },
  mounted() {
    this.Loading = true
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
      this.Loading = false
    }).catch((err) => {
      this.$Progress.fail()
      this.Loading = false
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      const PermissionSession = JSON.parse(localStorage.getItem('Permission'))
      this.Features = PermissionSession.Features.System.Basic
      const Executes = []
      if (this.Features.includes('B2BInfo')) {
        Executes.push(this.GetData('B2BInfo'))
      }
      if (this.Features.includes('B2BIdentity')) {
        Executes.push(this.GetData('B2BIdentity'))
      }
      if (this.Features.includes('B2BProduct')) {
        Executes.push(this.GetData('B2BProduct'))
      }
      return Promise.all(Executes)
    },
    GetData(type) {
      return this.$store.dispatch('InnerRequest', {
        url: '/system/get/' + type,
        method: 'post'
      }).then(({data}) => {
        this.$set(this.BasicData, type, data)
        return true
      }).catch((err) => {
        throw err
      })
    },
    SetData(type) {
      const data = {
        [type]: this.BasicData[type]
      }
      if (data.Info && data.Info.Domain) {
        data.Info.Domain = data.Info.Domain.replace('https://', '').replace('http://', '').replace('/', '')
      }
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/system/set/' + type,
        method: 'post',
        data
      }).then(() => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.System/ModifySuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.System/ModifyFail') + err.msg,
          type: (err.type ? err.type : 'error')
        })
      })
    },
    AddData(type) {
      if (!this.BasicData[type]) this.$set(this.BasicData, type, [])
      let Data
      let ID = crypto.randomBytes(8).toString('hex')
      switch (type) {
        case 'B2BIdentity':
          Data = {
            ID,
            Name: '經銷商' + (this.BasicData[type].length + 1),
            ShopDiscount: 100,
            Status: true
          }
          break
      }
      this.BasicData[type].push(Data)
    },
    RemoveData(type, index) {
      this.BasicData[type].splice(index, 1)
    },
    OpenMediaStore(type = 'Carousel', multiple = true, index = false, item = 'Cover') {
      this.ChooseImageType = type
      this.ChooseImageItem = item
      this.MediaStoreConfig.ChooseImageList = []
      this.MediaStoreConfig.Display = !this.MediaStoreConfig.Display
      this.MediaStoreConfig.Multiple = multiple
      if (index !== false) {
        this.ChooseImageIndex = index
      }
    },
    SetImages (type = 'ServiceLocation', index, item = '') {
      switch (type) {
        case 'B2BInfo':
          this.SetSingleImage(type, index, item)
          break
      }
    },
    SetSingleImage(type, index, item) {
      if (this.$store.state.user.permission.StaticDomain) {
        if (index) this.$set(this.BasicData[type][index], item, this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
        else this.$set(this.BasicData[type], item, this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
      } else {
        this.$store.state.userProjectApp.storage().ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
          if (index) this.$set(this.BasicData[type][index], item, URL)
          else this.$set(this.BasicData[type], item, URL)
        })
      }
    },
    OpenProductListModel(type, index = null) {
      this.ChooseProductType = type
      this.ChooseConditionIndex = index
      this.ProductListModel = true
      this.Single = type === 'Gift';
      this.ProductListModelComponent = () => import('@/components/product/ProductList')
    },
    ChooseProduct(items) {
      items.filter(item => item.Status === 1).forEach(item => {
        const Data = {
          Cover: item.Cover,
          SeriesNum: item.SeriesNum,
          ID: item._id,
          Name: item.Name,
          Amount: item.Amount
        }
        this.BasicData.B2BIdentity.forEach((item) => {
          Data.Amount[item.ID] = Math.round(Data.Amount.Actual * parseInt(item.ShopDiscount) / 100)
        })
        if (!this.BasicData.B2BProduct || this.BasicData.B2BProduct.length === 0) {
          this.$set(this.BasicData, 'B2BProduct', [])
        }
        switch (this.ChooseProductType) {
          case 'Default':
          case 'TargetItem':
            if (this.BasicData.B2BProduct.filter(data => data.SeriesNum === Data.SeriesNum).length > 0) {
              this.$notify({
                group: 'notify',
                title: this.$t('Message.Info'),
                text: this.$t('Message.Offer/ChooseProductFail-2'),
                type: 'warn'
              })
              return false
            }
            this.BasicData.B2BProduct.push(Data)
            break
        }
      })
      this.CloseProductListModel()
    },
    CloseProductListModel() {
      this.ProductListModel = false
      this.ProductListModelComponent = null
    }
  }
}
</script>

<style scoped>
.favicon {
  width: 64px;
  height: 64px;
}
.CarouselItem {
  min-height: initial;
}
</style>
